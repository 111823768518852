import { Route, Routes } from 'react-router-dom';

import { Preload } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { useSize } from '../stores/useSize';
import { CameraManager } from './core/CameraManager';
import { Staging } from './core/Staging';
import { Home } from './pages/home/Home';
import { Materials } from './Materials';

export function App3D() {
  /*
   * properties
   */

  const dpr = useSize((state) => state.dpr);
  const setDpr = useSize((state) => state.setDpr);

  /*
   * hooks
   */

  const performanceChange = (state) => {
    // console.log(state);

    const sum = state.averages.reduce((acc, val) => acc + val, 0);
    const averageFps = state.averages.length ? sum / state.averages.length : 0;
    // console.log(averageFps);

    const deviceDpr = Math.min(2, window.devicePixelRatio);

    if (averageFps < 24) {
      if (dpr === deviceDpr && averageFps > 14) {
        setDpr(deviceDpr * 0.75);
      } else if (dpr > deviceDpr * 0.5) {
        setDpr(deviceDpr * 0.5);
      } else if (post) {
        setPost(false);
      }
    }
  };

  return (
    <>
      <Canvas
        flat={true}
        dpr={dpr}
        gl={{
          powerPreference: 'high-performance',
          // alpha: false,
          antialias: true,
          stencil: true,
          // depth: false,
        }}
        style={{ zIndex: 0 }}
      >
        {/* <TestMaterials /> */}
        <Materials />
        {/* <PerformanceMonitor ms={250} iterations={5} onChange={performanceChange} bounds={(refreshrate) => [24, 200]} /> */}
        <Preload all />

        {/* <CameraManager /> */}
        {/* <Staging /> */}

        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Canvas>
    </>
  );
}
