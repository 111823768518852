import { useEffect, useRef } from 'react';
import { MaskedPicture } from './MaskedPicture';
import { Html } from '@react-three/drei';
import style from './Member.module.css';
import { useMainScroll } from '../../../../stores/useMainScroll';
import { useFrame } from '@react-three/fiber';
import { MathUtils } from 'three';
import { useTranslation } from 'react-i18next';

export function Member({ index, angleGap, yGap, amount }) {
  /*
   * properties
   */

  const { t } = useTranslation('translation', { keyPrefix: 'member' + index });

  const panel = useRef();

  const getSectionPct = useMainScroll((state) => state.getSectionPct);

  /*
   * hooks
   */

  useFrame(() => {
    let pct = getSectionPct(3);

    const active = pct > 0 && pct < 1;

    if (active) {
      const start = MathUtils.mapLinear(pct, 0, 0.1, 0, 1);
      const end = MathUtils.mapLinear(pct, 0.9, 1, 1, 0);
      let num = MathUtils.mapLinear(pct, 0.1, 0.9, 0, 1);
      num = 1 - Math.max(0, Math.min(1, Math.abs(num * (amount - 1) - index)));
      num = Math.min(start, end, num);

      // panel.current.style.opacity = Math.pow(num, 0.6);
      panel.current.style.opacity = Math.pow(num, 1.8);
    } else {
      panel.current.style.opacity = 0;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <group rotation={[0, angleGap * index, 0]}>
        <group position={[0, yGap * index, 2.8]}>
          <group scale={1.4}>
            <MaskedPicture
              map={'team' + (index + 1)}
              displacementMap={'team' + (index + 1) + 'Depth'}
              maskId={index + 1}
            />
          </group>
          <Html
            transform
            zIndexRange={[20, 0]}
            distanceFactor={1.8}
            position={[0, 0, -0.4]}
            wrapperClass={style.wrapper}
          >
            <div ref={panel} className={style.panel}>
              <h2>{t('name')}</h2>
              <p>{t('function')}</p>
            </div>
          </Html>
        </group>
      </group>
    </>
  );
}
