import { useProgress } from '@react-three/drei';
import { useEffect, useRef, useState } from 'react';
import style from './Preloader.module.css';
import { useSections } from '../../../stores/useSections';
import gsap from 'gsap';

export function Preloader() {
  /*
   * properties
   */

  const container = useRef();
  const { active, progress } = useProgress();
  const [shown, setShown] = useState(true);
  const setLoaded = useSections((state) => state.setLoaded);

  /*
   * hooks
   */

  // useEffect(() => {
  //   console.log('Preloader onMount');
  // }, []);

  useEffect(() => {
    // console.log('progress: ' + progress);

    if (progress === 100) {
      setLoaded(true);
      gsap.to(container.current, {
        onComplete: () => {
          setShown(false);
        },
        opacity: 0,
        duration: 0.5,
        ease: 'power4.out',
      });
    }
  }, [progress]);

  // useEffect(() => {
  //   // console.log('active: ' + active);

  //   if (active && !shown) {
  //     console.log('show');
  //     setShown(true);
  //   } else if (!active && shown) {
  //     console.log('hide');
  //     setShown(false);

  //     setLoaded(true);

  //     // setTimeout(() => {
  //     //   setLoaded(true);
  //     // }, 3000);
  //   }
  // }, [active]);

  /*
   * visuals
   */

  return (
    <>
      {shown && (
        <div ref={container} className={style.preloader}>
          <div className={style.inner} style={{ width: progress + '%' }} />
          <p className={style.text}>{Math.round(progress)}%</p>
        </div>
      )}
    </>
  );
}
